import * as React from "react"
import { Link, graphql } from "gatsby"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
	const post = data.markdownRemark
	const siteTitle = data.site.siteMetadata?.title || `Title`
	const { previous, next } = data
	const tags = post.frontmatter.tags


	return (
		<Layout location={location} title={siteTitle}>
			<Seo
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
			/>
			<article
				className="blog-post"
				itemScope
				itemType="http://schema.org/Article"
			>
				<header>
					<div className="topstuff">
						{/* <p className="dateheader">From: <Link to="https://www.shad0w.ml">Shad0wBlogBeta</Link></p> */}
						<p className="dateheader">Published: <Link to="/">{post.frontmatter.date}</Link></p>
						<p className="dateheader">Tags: <Link to="#">{tags[0]}</Link></p>
						
					</div>

					<h1 itemProp="headline " className="serif">{post.frontmatter.title}</h1>

					<p className="description">{post.frontmatter.description}</p>
					<div class="tagholder">
						{/* if(tags != null) {
						 tags.map((tag) => {
							return [
								<div
									key={tag}
									variant="outlined"
									onClick={event => { navigate(`/tag/${tag}`) }}
									// https://juliapottinger.com/add-tags-to-your-gatsby-blog/ more info on this

								>
									<p>
										{tag}
									</p>
								</div>
							]
						})} else {
							<p>No Tags</p>
						} */}
					
					</div>


				</header>


				<section
					dangerouslySetInnerHTML={{ __html: post.html }}
					itemProp="articleBody"
				/>


				<nav className="blog-post-nav">
					<ul
						style={{
							display: `flex`,
							flexWrap: `wrap`,
							justifyContent: `space-between`,
							listStyle: `none`,
							padding: 0,
						}}
					>
						<li>
							{previous && (
								<Link to={previous.fields.slug} rel="prev" className="prev">
									<span>←</span> {previous.frontmatter.title}
								</Link>
							)}
						</li>
						<li>
							{next && (
								<Link to={next.fields.slug} rel="next" className="next">
									{next.frontmatter.title} <span>→</span>
								</Link>
							)}
						</li>
					</ul>
				</nav>

			</article>

		</Layout>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String

  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
		tags
		
		
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }


  }
`
